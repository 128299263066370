<template>
    <v-container fluid>
      <v-card class="pa-2 mb-2">
        <v-row>
          <v-col cols="12" md="4" lg="2">
            <DatePicker
              v-model="searchFilters.from"
              label="Issued after"
              show-clear
              hide-details
              dense
              :loading="busy"
            />
          </v-col>
          <v-col cols="12" md="4" lg="2">
            <DatePicker
              v-model="searchFilters.to"
              label="Issued before"
              show-clear
              hide-details
              dense
              :loading="busy"
            />
          </v-col>
          <v-col cols="12" md="4" lg="2">
            <v-text-field
              hide-details
              outlined
              clearable
              dense
              label="i_customer"
              :disabled="busy"
              v-model="searchFilters.i_customer"
            />
          </v-col>
          <v-col cols="12" md="4" lg="2">
            <v-select
              hide-details
              outlined
              dense
              label="Status"
              v-model="searchFilters.status"
              :disabled="busy"
              :items="statusOptions"
              item-text="option"
              item-value="value"
            />
          </v-col>
          <v-col cols="6" md="2" lg="1">
            <v-btn
              class="secondary"
              outlined
              block
              height="40"
              :loading="busy"
              @click="update"
            >Run</v-btn>
          </v-col>
          <v-col cols="6" md="2" lg="1">
            <v-btn
              class="secondary"
              outlined
              block
              height="40"
              :loading="busy"
              @click="downloadCSV"
            >CSV</v-btn>
          </v-col>
        </v-row>
      </v-card>
        <v-row>
            <v-col cols="12">
              <PaginationCardV2
                :page="searchFilters.page"
                :totalRecords="listData.count"
                :totalPages="listData.pages"
                :limit="searchFilters.limit"
                :currentPageTotal="(listData.data) ? listData.data.length : 0"
                :loading="busy"
                @updatedPagination="updatedPagination"
                :no-skeleton="false"
              >
                <v-simple-table fixed-header>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Customer</th>
                      <th class="text-center">Issue Date</th>
                      <th class="text-center">Period Total</th>
                      <th class="text-center">
                        Payments/Adjustments
                      </th>
                      <th class="text-center">
                        Outstanding balance
                      </th>
                      <th>Status</th>
                    </tr>
                    </thead>
                    <v-slide-x-transition tag="tbody" hide-on-leave group>
                      <tr v-if="listData.data && listData.data.length === 0" key="nodata">
                        <td colspan="100%" class="text-center">
                          No invoices found
                        </td>
                      </tr>
                      <tr
                        v-for="item in listData.data"
                        :key="item.i_invoice"
                      >
                      <td>{{ item.i_invoice }}</td>
                      <td>
                        <router-link
                          :to="'/customer/' + item.i_customer +'/summary'"
                        >{{
                            item.customer_name
                          }}</router-link
                        >
                      </td>
                      <td class="text-center">
                        {{ item.issue_date }}
                      </td>
                      <td class="text-center">
                        <Currency
                          :value="item.amount_net"
                          hide-tooltip
                        />
                      </td>
                      <td class="text-center">
                        <Currency
                          :value="item.amount_paid"
                          hide-tooltip
                        />
                      </td>
                      <td class="text-center">
                        <Currency
                          :value="
                                                item.amount_net -
                                                item.amount_paid
                                            "
                          hide-tooltip
                        />
                      </td>
                      <td>
                        <v-chip
                          v-if="item.invoice_status == 'Overdue'"
                          color="red"
                          text-color="white"
                          label
                        >Overdue</v-chip>
                        <v-chip
                          v-else-if="item.invoice_status == 'Paid'"
                          color="green"
                          text-color="white"
                          label
                        >Paid</v-chip>
                        <v-chip
                          v-else
                          color="orange"
                          text-color="white"
                          label
                        >{{ item.invoice_status }}</v-chip>
                      </td>
                    </tr>
                  </v-slide-x-transition>
                </v-simple-table>
              </PaginationCardV2>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Currency from '../../../components/pieces/Currency';
import DatePicker from '../../../components/pieces/Forms/DatePicker';
import apiMixin from '../../../mixins/apiMixin';
import PaginationCardV2 from '../../../components/cards/templates/PaginationCardV2.vue';
export default {
    name: 'UnpaidInvoiceList',
    mixins: [apiMixin],
    data: () => ({
      linkBase: process.env.VUE_APP_API_BASE || '/api/',
      busy:false,
      searchFilters: {
        page:1,
        limit: 10,
        from: null,
        to: null,
        status: 'COMPLETED',
        i_customer: null,
      },
      listData: {
        total_records:0,
        total_pages:0
      },
      statusOptions: [
        { option: 'Any', value: null },
        { option: "don't pay", value: 1 },
        { option: 'unpaid', value: 2 },
        { option: 'partially paid', value: 3 },
        { option: 'overdue', value: 4 },
        { option: 'paid', value: 5 },
        { option: 'no payment required', value: 6 },
        { option: 'previous balance remaining', value: 7 },
        { option: 'under review', value: 8 },
        { option: 'rejected', value: 9 },
        { option: 'approved', value: 10 },
      ],
    }),
    components: {
        PaginationCardV2,
        DatePicker,
        Currency,
    },
    methods: {
      updatedPagination(data){
        this.searchFilters.page = data.page;
        this.searchFilters.limit = data.limit;
        this.update( );
      },

      getParams( ){
        let params = { ...this.searchFilters }; //shallow clone

        //convert the dates
        if(params.from){
          params.from_date = params.from;
          delete(params.from);
        }

        if(params.to){
          params.to_date = params.to;
          delete(params.to);
        }

        if(params.from_date == ''){
          params.from_date = null;
        }
        if(params.to_date == ''){
          params.to_date = null;
        }
        // if(params.from_date != null){
        //   params.from_date = params.from_date+' 00:00:00';
        // }
        // if(params.to_date != null){
        //   params.to_date = params.to_date+' 23:59:59';
        // }
        return params;
      },

      async update() {
        this.busy = true;
        let params = this.getParams();
        let queryString = Object.keys(params)
          .filter(key => params[key] !== null && params[key] !== undefined)  // Remove null or undefined values
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
          .join('&');

        const response = await this.Api.send('get','admin/invoice/list?' + queryString);
        this.busy = false;
        if (response.success) {
          this.listData = response.data;
          this.paginationData = {
            data: response.data.data,
            count: response.data.count,
            pages: response.data.pages
          };
        } else {
          this.listData = [];
        }
      },

      downloadCSV( )
      {
        this.update( );
        let params = this.getParams();
        params['session-token'] = this.$store.state.session.token;
        let queryString = Object.keys(params)
          .filter(key => params[key] !== null && params[key] !== undefined)  // Remove null or undefined values
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
          .join('&');
        //const url = this.linkBase + 'admin/finances/credit-card-transactions?session-token=' + this.$store.state.session.token;
        const url = this.linkBase + 'admin/invoice/list/csv?' + queryString;
        window.location.href = url;
      },
    },
    watch: {
        $route(to) {
            this.searchFilters.to = to.query.to ? to.query.to : null;
            this.searchFilters.from = to.query.from ? to.query.from : null;
            this.searchFilters.status = to.query.status ? to.query.status : null;
            this.update();
        },
    },
    created() {
      this.searchFilters.to = this.$route.query.to ? this.$route.query.to : null;
      this.searchFilters.from = this.$route.query.from ? this.$route.query.from : null;
      this.searchFilters.status = this.$route.query.status ? this.$route.query.status : null;
      this.update();
    },
};
</script>
